.tabs__nav {
  display: table;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.tabs__nav-item {
  display: table-cell;
  padding: 15px 0;
  border: solid;
  border-color: #ccc;
  border-width: 0 0 1px 1px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'lato-bold';
  color: #5f5f5f;

  &:hover:not(.tabs__nav-item--active) {
    color: #484646;
  }

  &:first-child {
    border-left: 0;
  }
}

.tabs__nav-item--active {
  color: #1088f2;
  border-width: 0 0 0 1px;
}
