@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

$fixedHeaderHeight: 50px;

.white {
  background-color: #fff;
}

.grey {
  background-color: #f1f1f1;
}

.hollowButton {
  background-color: transparent;
  border: 2px solid $brand-purple;
  border-radius: 4px;
  color: $brand-purple;
  padding: 3px 15px;
  position: relative;
  top: -5px;
  transition: box-shadow 0.25s ease;

  &:active,
  &:visited,
  &:hover {
    text-decoration: none;
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 10px #888888;

    &.whiteHollow {
      background-color: $brand-purple;
    }
  }
}

.logoContainer {
  float: left;
}

.fixedHeader {
  position: fixed;
  width: 100%;
  height: $fixedHeaderHeight;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  z-index: 1000;
  overflow: hidden;
  box-shadow: 0 0 0.125em 0 rgba(0, 0, 0, 0.5);

  .fixedLogo {
    margin-top: 9px;
  }

  .hollowButton {
    border-color: $brand-purple;
    color: $brand-purple;
    float: right;
    margin-top: 14px;
  }
}

.staticHeader {
  padding: 30px 0 0 0;
  display: none;

  @media screen and (min-width: $screen-sm) {
    display: block;
  }

  .leftMenu,
  .rightMenu {
    list-style-type: none;
    padding: 0;
    margin: 7px 0 0 0;
    font-weight: 700;
    font-size: 16px;
    height: 100%;

    &.whiteText {
      #nav-menu-user-dropdown > li > a {
        color: $brand-purple;
      }

      &:not(#nav-menu-user-dropdown) > li > a {
        color: #fff;

        &:not(.hollowButton) {
          &:before {
            background-color: #fff;
          }
        }
      }

      .hollowButton {
        border-color: #fff;
      }
    }

    li {
      float: left;

      a {
        display: block;
        margin-left: 20px;
        color: $brand-purple;
        position: relative;
        text-decoration: none;

        &:not(.hollowButton) {
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $brand-purple;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
          }

          &:hover:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  .leftMenu {
    float: left;
  }

  .rightMenu {
    float: right;
  }
}

.staticHeaderMobile {
  padding-top: 20px;
  margin-left: 15px;
  display: block;

  :global {
    .container {
      padding: 0;
    }
  }

  @media screen and (min-width: $screen-sm) {
    display: none;
  }

  .hamburgerIcon {
    float: right;
    margin-right: 20px;
    position: relative;
    width: 30px;
    height: 25px;
    cursor: pointer;

    &.whiteHamburger > div {
      background-color: #fff;
    }

    div {
      background-color: $brand-purple;
      height: 5px;
      border-radius: 2px;
      width: 30px;
      position: absolute;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
        transform: rotate(0deg);
      }

      &:nth-child(4) {
        top: 20px;
      }
    }

    &.open {
      z-index: 51;

      div {
        &:nth-child(1) {
          transform: scale(0);
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          transform: scale(0);
        }
      }
    }
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    background-color: $brand-purple;
    text-align: center;
    width: 275px;

    ul {
      list-style-type: none;
      padding: 20px;

      .hollowButton {
        margin-bottom: 20px;
        border-color: #fff;
      }

      a {
        display: block;
        color: #fff;
        position: relative;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;

        &:not(.hollowButton) {
          padding: 10px 0;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #fff;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
          }

          &:hover:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

.mobileOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
}

:global {
  .animate-fixed-header-enter {
    height: 1px;
    opacity: 0.01;
  }

  .animate-fixed-header-enter.animate-fixed-header-enter-active {
    height: $fixedHeaderHeight;
    opacity: 1;
    transition: all 200ms;
  }

  .animate-fixed-header-exit {
    height: $fixedHeaderHeight;
    opacity: 1;
    transition: all 200ms;
  }

  .animate-fixed-header-exit.animate-fixed-header-exit-active {
    height: 1px;
    opacity: 0.01;
  }

  .dropdown-menu > li > a {
    margin-left: 0px !important;
  }
}
