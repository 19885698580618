@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: transparent;

  .largeFooter {
    height: 1230px;
    @media all and (min-width: $screen-sm) {
      height: 475px;
    }
  }

  strong {
    color: #777;
  }

  nav {
    padding-top: 20px;
    padding-bottom: 20px;

    .largeFooterNav {
      border-top: 1px solid #ddd;
    }
  }

  &.inverse {
    background-color: #000;
    color: $brand-info;

    nav {
      border-top: 0;
    }
  }

  .footerList {
    float: left;
    margin-top: 1.2em;
    text-align: left;
    padding: 0;
    width: 100%;

    .title {
      margin-left: -1em;
      margin-bottom: 1.5em;
      text-transform: uppercase;
    }

    .subTitle {
      margin-left: -1em;
    }

    li {
      list-style: none;
      margin-bottom: 7px;
      padding-left: 1em;

      a {
        color: #777;

        &:hover {
          color: #529ee0;
        }
      }
    }
  }

  .socialList {
    display: block;
    text-align: left;
    padding-left: 0px;

    @media all and (max-width: $screen-sm) {
      margin-top: 1.5em;
    }

    & > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .copyright {
    color: $brand-darkgrey;
    margin-left: -2em;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: $screen-sm) {
      display: block;
    }

    @media all and (min-width: $screen-sm) {
      margin-left: -3em;
    }
  }
}
