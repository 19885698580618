@import 'src/theme/variables.scss';

.brand {
  width: 95px;

  > svg {
    fill: #fff;
    height: inherit;
    width: inherit;
    position: relative;
    top: -6px;
    transition: fill 0.3s;
  }
  &:hover {
    > svg {
      fill: $brand-primary;
    }
  }
}

.navMenu {
  ul {
    padding: 0;
    background-color: $navbar-inverse-bg;
    width: 200px;
    border: 0;

    li > a {
      color: #fff;
      padding: 6px 10px;

      &:hover,
      &:active {
        background-color: $brand-primary;
        color: #fff;
      }
    }
  }

  :global {
    .dropdown-menu .divider {
      margin: 0;
      background-color: #4d4d4d;
    }
  }
}
