.fade {
  img,
  a,
  div {
    -webkit-animation: fadein 250ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 250ms; /* Firefox < 18 */
    -ms-animation: fadein 250ms; /* Internet Explorer */
    -o-animation: fadein 250ms; /* Opera < 12.1 */
    animation: fadein 250ms;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
