@import 'src/theme/variables.scss';

.brand {
  width: 95px;

  > svg {
    fill: #fff;
    height: inherit;
    width: inherit;
    position: relative;
    top: -6px;
    transition: fill 0.3s;
  }
  &:hover {
    > svg {
      fill: $brand-primary;
    }
  }
}

.StickyNavBar {
  top: 0;
  left: 0;
  position: sticky;
  z-index: 2;
}

.ctaContainer {
  margin-top: 20px;
}

.StickyNavContent {
  display: flex;
  justify-content: space-between;
}
