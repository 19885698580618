@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(40, 40, 40, 0.4);
  z-index: 1000;
}

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  overflow-y: scroll;
}

.modal {
  width: calc(100% - 40px);
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: auto;
  margin-bottom: auto;
  filter: none;

  @media screen and (min-width: $screen-sm) {
    width: 450px;
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }

  @media screen and (min-width: $screen-lg) {
    width: 600px;
  }
}

.oversizedModal {
  top: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  transform: translate(-50%, 0);
}

.closeButton {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  padding: 0 40px;
}

@media screen and (min-width: $screen-md) {
  .title {
    font-size: 26px;
  }
}

.subtitle {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  padding: 0 40px;
}

@media screen and (min-width: $screen-md) {
  .subtitle {
    font-size: 20px;
  }
}

.body {
  font-size: 16px;
  color: #333;
}

// Animations - background
:global {
  .modalBackgroundAnimation-enter {
    opacity: 0.01;
  }

  .modalBackgroundAnimation-enter.modalBackgroundAnimation-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .modalBackgroundAnimation-leave {
    opacity: 1;
  }

  .modalBackgroundAnimation-leave.modalBackgroundAnimation-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
}

// Animations - slideDown
:global {
  .animate-slideDown-enter {
    opacity: 0.01;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .animate-slideDown-enter.animate-slideDown-enter-active {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 200ms;
  }

  .animate-slideDown-leave {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 200ms;
  }

  .animate-slideDown-leave.animate-slideDown-leave-active {
    opacity: 0.01;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}

// Animations - slideUp
:global {
  .animate-slideUp-enter {
    opacity: 0.01;
    transform: translateY(50%);
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
  }

  .animate-slideUp-enter.animate-slideUp-enter-active {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 200ms;
  }

  .animate-slideUp-leave {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 200ms;
  }

  .animate-slideUp-leave.animate-slideUp-leave-active {
    opacity: 0;
    transform: translateY(50%);
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
  }
}

// Animations - slideLeft
:global {
  .animate-slideLeft-enter {
    opacity: 0.01;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }

  .animate-slideLeft-enter.animate-slideLeft-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideLeft-leave {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideLeft-leave.animate-slideLeft-leave-active {
    opacity: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
}

// Animations - slideLeftRight
:global {
  .animate-slideLeftRight-enter {
    opacity: 0.01;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }

  .animate-slideLeftRight-enter.animate-slideLeftRight-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideLeftRight-leave {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideLeftRight-leave.animate-slideLeftRight-leave-active {
    opacity: 0;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
  }
}

// Animations - slideRight
:global {
  .animate-slideRight-enter {
    opacity: 0.01;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
  }

  .animate-slideRight-enter.animate-slideRight-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideRight-leave {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .animate-slideRight-leave.animate-slideRight-leave-active {
    opacity: 0;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
  }
}

// Animations - scale
:global {
  .animate-scale-enter {
    opacity: 0.01;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
  }

  .animate-scale-enter.animate-scale-enter-active {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transition: all 200ms;
  }

  .animate-scale-leave {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transition: all 200ms;
  }

  .animate-scale-leave.animate-scale-leave-active {
    opacity: 0.01;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
  }
}
