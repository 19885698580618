/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */
@use 'sass:math';

// Custom Colors
$cyan: #33e0ff;
$humility: #777777;

// Bootstrap Variables
$brand-primary: #0777da; // #40a0f5
$brand-secondary: #e25139;
$brand-success: #2cae76; // #128264; // #0fe2af;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #eee;
$brand-darkgrey: #777;
$brand-purple: #322954;
$brand-navy: #363c6e;
$brand-accent-purple: #732fbc;
$brand-dark: #04121f;

$text-color: #333;
$body-bg: #ffffff;

$font-size-base: 14px;
$font-family-sans-serif: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Panel shadow
$box-shadow: 3px 3px 32px rgba(12, 18, 39, 0.08);

// Navbar
$navbar-height: 73px;
$navbar-inverse-bg: $brand-dark;

// Buttons
$btn-primary-border: $brand-primary;
$btn-success-border: $brand-success;
$btn-info-color: #555;
$btn-info-border: $brand-info;

// List group
$list-group-border: #eee;

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be a different size and not have it effect grid size too,
// set the value of $em-base to $base-font-size ($em-base: $base-font-size;)
$em-base: 16px !default;

$screen-xl: 1200px;

// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

// Converts "px" to "em" using the ($)em-base
@function convert-to-em($value, $base-value: $em-base) {
  $value: math.div(strip-unit($value), strip-unit($base-value)) * 1em;
  @if ($value == 0em) {
    $value: 0;
  } // Turn 0em into 0
  @return $value;
}

// Working in ems is annoying. Think in pixels by using this handy function, em-calc(#)
// Just enter the number, no need to mention "px"
@function em-calc($values, $base-value: $em-base) {
  $max: length($values); // Get the total number of parameters passed

  // If there is only 1 parameter, then return it as an integer.
  // This is done because a list can't be multiplied or divided even if it contains a single value
  @if $max == 1 {
    @return convert-to-em(nth($values, 1), $base-value);
  }

  $emValues: (); // This will eventually store the converted $values in a list
  @for $i from 1 through $max {
    $emValues: append($emValues, convert-to-em(nth($values, $i), $base-value));
  }
  @return $emValues;
}
