/* Switch Toggle
   ========================================================================== */

.switch-toggle {
  background: #dddfe0;
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: bold;
  padding: 7px 0px;
  border-radius: 4px;
  overflow: hidden;
  min-width: 75px;

  &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background: #dc4633;
    border-radius: 0 4px 4px 0;
    top: 0;
    left: 50%;
    z-index: -1;
    transition: all 0.2s ease;
  }

  &.on {
    &:after {
      left: 0;
      background: #0fe2af;
      border-radius: 4px 0 0 4px;
      color: #fff;
    }

    div {
      &:nth-child(1) {
        color: #fff;
      }

      &:nth-child(2) {
        color: #828282;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.65;

    &:after {
      background-color: #828282;
    }
  }

  div {
    transition: all 0.2s ease;
    color: #828282;
    display: inline-block;
    width: 50%;
    text-align: center;

    &:nth-child(2) {
      color: #fff;
    }
  }
}
