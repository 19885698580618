@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  box-shadow: $box-shadow;
  margin: 5em auto;
  text-align: center;
  padding: 20px 3em;
  width: 410px;

  @media screen and (max-width: $screen-md) {
    margin: 5em 1em;
    width: auto;
  }
}
