@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.titleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  margin: 10px 0;
}

.projectButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  align-items: center;
  display: flex;
  gap: 5px;
  padding: 10px 12px;
  font-weight: 700;
}

.noProjectsTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
