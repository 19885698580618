@import 'src/theme/variables.scss';

$successColor: $brand-success;
$errorColor: $brand-danger;
$warningColor: $brand-warning;
$infoColor: $brand-info;

.container {
  text-align: center;
}

.body {
  margin: 20px auto;
}

.title {
  font-weight: 700;
  font-size: 20px;
  margin: 20px auto;
}

.text {
  margin: 20px auto;
}

.buttons {
  margin-top: 20px;

  button {
    margin: 0 10px;
  }
}

.icon {
  position: relative;

  width: 80px;
  height: 80px;

  border: 4px solid gray;
  border-radius: 50%;

  margin: auto;
  padding: 0;

  box-sizing: content-box;

  &.alertBoxIconError {
    border-color: $errorColor;

    .alertBoxIconXMark {
      position: relative;
      display: block;
    }

    .alertBoxIconLine {
      display: block;
      position: absolute;
      top: 37px;
      height: 5px;
      width: 47px;

      background-color: $errorColor;
      border-radius: 2px;
    }

    .alertBoxIconLeft {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 17px;
    }

    .alertBoxIconRight {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      right: 16px;
    }
  }

  &.alertBoxIconWarning {
    border-color: $warningColor;

    .alertBoxIconBody {
      // Exclamation mark body
      position: absolute;
      width: 5px;
      height: 47px;
      left: 50%;
      top: 10px;

      margin-left: -2px;

      border-radius: 2px;
      background-color: $warningColor;
    }

    .alertBoxIconDot {
      // Exclamation mark dot
      position: absolute;
      left: 50%;
      bottom: 10px;
      width: 7px;
      height: 7px;

      margin-left: -3px;

      border-radius: 50%;
      background-color: $warningColor;
    }
  }

  &.alertBoxIconInfo {
    border-color: $infoColor;

    &::before {
      // i-letter body
      content: '';
      position: absolute;
      width: 5px;
      height: 29px;
      left: 50%;
      bottom: 17px;

      margin-left: -2px;

      border-radius: 2px;
      background-color: $infoColor;
    }

    &::after {
      // i-letter dot
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      top: 19px;

      margin-left: -3px;

      border-radius: 50%;
      background-color: $infoColor;
    }
  }

  &.alertBoxIconSuccess {
    border-color: $successColor;

    .alertBoxIconPlaceholder {
      // Ring
      box-sizing: content-box;
      position: absolute;
      left: -4px;
      top: -4px;
      z-index: 2;

      width: 80px;
      height: 80px;

      border: 4px solid $successColor;
      border-radius: 50%;
    }

    .alertBoxIconFix {
      // Hide corners left from animation
      position: absolute;
      left: 28px;
      top: 8px;
      z-index: 1;

      width: 7px;
      height: 90px;
      background-color: white;

      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .alertBoxIconLine {
      display: block;
      position: absolute;
      z-index: 2;

      height: 5px;
      background-color: $successColor;
      border-radius: 2px;

      &.alertBoxIconTip {
        width: 25px;

        left: 14px;
        top: 46px;

        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.alertBoxIconLong {
        width: 47px;

        right: 8px;
        top: 38px;

        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}

:global {
  .alertBoxIconAnimation-enter {
    opacity: 0;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transition: all 200ms;
    transition-delay: 200ms;
  }

  .alertBoxIconAnimation-enter.alertBoxIconAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
  }

  .alertBoxIconAnimation-leave {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 200ms;
  }

  .alertBoxIconAnimation-leave.animate-slideRight-leave-active {
    opacity: 0;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
  }
}
