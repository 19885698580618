@font-face {
  font-family: 'TwineIcons';
  src: url('../../static/fonts/TwineIcons.woff2') format('woff2');
  font-display: swap;
}

@import './mixins';
@import './toggle-switch';
@import './brief-details';
@import './messages';
@import './landing-page';
@import './skills';
@import './portfolio';

@import './tabs';
@import '../../node_modules/react-datepicker/dist/react-datepicker.min';
@import './react-datepicker-overrides';
$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';

body {
  transition: 0.25s ease-in-out;

  &.swipeMenu {
    transform: translateX(-275px);
  }

  &.white-background {
    background-color: #fff;
  }
}

p {
  font-size: 16px;
}

.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.walkthroughModal {
  top: 40%;
}

.walkthroughModal .modal-content {
  background-color: rgb(60, 150, 230);
  color: #fff;
  font-size: 1.1em;
  padding: 20px;
}

.walkthrough.popover {
  background-color: rgb(60, 150, 230);
  color: #fff;
  padding: 10px;
}

.walkthrough.popover.right {
  .arrow:after {
    border-right-color: rgb(60, 150, 230);
  }
}

.walkthrough.popover.top {
  .arrow:after {
    border-top-color: rgb(60, 150, 230);
  }
}

.walkthrough.popover.bottom {
  .arrow:after {
    border-bottom-color: rgb(60, 150, 230);
  }
}

.user-card-hover .popover-content {
  padding: 0;
}

.user-card-hover.popover {
  margin: 1px;
  padding: 0;
  width: 100%;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.my_message a {
  color: white;
}

.my_message a:hover {
  color: white;
  text-decoration: underline;
}

.oh {
  overflow: hidden;
}

.round {
  border-radius: 1000px;
  -moz-border-radius: 1000px;
  -webkit-border-radius: 1000px;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.lato-regular {
  font-family: Lato;
}

.lato-bold {
  font-family: Lato;
  font-weight: 700;
}

.black {
  color: #000;
}

.m {
  margin: 1em;
}

.mt {
  margin-top: 1em;
}

.mt\+ {
  margin-top: 1.25em;
}

.mt\+\+ {
  margin-top: 1.5em;
}

.mt- {
  margin-top: 0.5em;
}

.mt-- {
  margin-top: 0.25em;
}

.ml {
  margin-left: 1em;
}

.ml\+ {
  margin-left: 1.25em;
}

.ml\+\+ {
  margin-left: 1.5em;
}

.ml- {
  margin-left: 0.5em;
}

.ml-- {
  margin-left: 0.25em;
}

.mr {
  margin-right: 1em;
}

.mr\+ {
  margin-right: 1.25em;
}

.mr\+\+ {
  margin-right: 1.5em;
}

.mr- {
  margin-right: 0.5em;
}

.mr-- {
  margin-right: 0.25em;
}

.mb {
  margin-bottom: 1em;
}

.mb\+ {
  margin-bottom: 1.25em;
}

.mb\+\+ {
  margin-bottom: 1.5em;
}

.mb- {
  margin-bottom: 0.5em;
}

.mb-- {
  margin-bottom: 0.25em;
}

.p {
  padding: 1em;
}

.p\+ {
  padding: 1.25em;
}

.p\+\+ {
  padding: 1.5em;
}

.p- {
  padding: 0.5em;
}

.p-- {
  padding: 0.25em;
}

.pl {
  padding-left: 1em;
}

.pl\+ {
  padding-left: 1.25em;
}

.pl\+\+ {
  padding-left: 1.5em;
}

.pl- {
  padding-left: 0.5em;
}

.pl-- {
  padding-left: 0.25em;
}

.pr {
  padding-right: 1em;
}

.pr\+ {
  padding-right: 1.25em;
}

.pr\+\+ {
  padding-right: 1.5em;
}

.pr- {
  padding-right: 0.5em;
}

.pr-- {
  padding-right: 0.25em;
}

.pt {
  padding-top: 1em;
}

.pt\+ {
  padding-top: 1.25em;
}

.pt\+\+ {
  padding-top: 1.5em;
}

.pt- {
  padding-top: 0.5em;
}

.pt-- {
  padding-top: 0.25em;
}

.pb {
  padding-bottom: 1em;
}

.pb\+ {
  padding-bottom: 1.25em;
}

.pb\+\+ {
  padding-bottom: 1.5em;
}

.pb- {
  padding-bottom: 0.5em;
}

.pb-- {
  padding-bottom: 0.25em;
}

.body-color {
  color: #333;
}

.dark-grey {
  color: #777;
}

.light-grey {
  color: #aaa;
}

.body-colour {
  color: $text-color;
}

.no-radius {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.full-width {
  width: 100%;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.radius-4 {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.cf:after {
  content: '';
  display: table;
  clear: both;
}

#pitch-display {
  border: 1px solid #eee;
  position: relative;
}

#pitch-display:before {
  content: '';
  display: block;
  position: absolute;
  top: -8px;
  left: 15px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#pitch-amount-display {
  border: 1px solid #eee;
  position: relative;
  top: -18px;
  margin-bottom: -10px;
}

.slideUp-leave {
  opacity: 1;
}

.slideUp-leave.slideUp-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.notification {
  cursor: pointer;
  background-color: lightblue;

  & :hover {
    background-color: blue;
  }

  .notification-read {
    background-color: #fff;
  }

  & > div {
    margin: 10px 15px;
  }
}

.ib {
  display: inline-block;
}

.right {
  float: right;
}

.absolute {
  position: absolute;
}

.animateHeight {
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
}

.animateVisibility {
  -webkit-transition: opacity 0.8s;
  -moz-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.slide {
  transition: max-height 1s ease;
  max-height: 0px;
  overflow: hidden;
}

.slide.in {
  max-height: 1000px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-purple {
  color: #312855;
}

.text-white {
  color: #fff;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.bg-pattern {
  background-repeat: repeat;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #f5f5f5;
}

.bg-purple {
  background-color: #322954;
}

.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 18px;
}
.fs-30 {
  font-size: 20px;
}
.fs-58 {
  font-size: 32px;
}

@media (min-width: $screen-sm-min) {
  .fs-28 {
    font-size: 24px;
  }
  .fs-30 {
    font-size: 26px;
  }
  .fs-58 {
    font-size: 44px;
  }
}

@media (min-width: $screen-md-min) {
  .fs-28 {
    font-size: 28px;
  }
  .fs-30 {
    font-size: 30px;
  }
  .fs-58 {
    font-size: 58px;
  }
}

.nowrap {
  white-space: nowrap;
}

.left {
  float: left;
}

.right {
  float: right;
}

.btn-mobile-expand {
  width: 100%;

  @media (min-width: $screen-md-min) {
    width: auto;
  }
}

.btn-hollow,
.btn-hollow:hover,
.btn-hollow:active,
.btn-hollow:visited,
.btn-hollow:focus {
  padding: 6px 20px;
  background-color: transparent;
  border: solid 2px #787878;
  color: #787878;
  border-radius: 4px;
}

.btn-purple,
.btn-purple:hover,
.btn-purple:active,
.btn-purple:visited,
.btn-purple:focus {
  background-color: #322954;
  border-color: #322954;
  color: #fff;
}

.btn-light-purple,
.btn-light-purple:hover,
.btn-light-purple:active,
.btn-light-purple:visited,
.btn-light-purple:focus {
  background-color: #8f4ad4;
  border-color: #8f4ad4;
  color: #fff;
}

.btn-darkgrey,
.btn-darkgrey:hover,
.btn-darkgrey:active,
.btn-darkgrey:visited,
.btn-darkgrey:focus {
  background-color: #787878;
  border-color: #787878;
  color: #fff;
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #2d4373;
  color: #fff;
}
.btn-facebook:focus,
.btn-facebook:hover {
  background-color: #4264aa !important;
  outline: none !important;
}
.btn-facebook:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-facebook:hover,
.btn-facebook:focus {
  color: #fff !important;
}

.btn-twitter {
  background-color: #00aced;
  border-color: #0087ba;
  color: #fff;
}
.btn-twitter:focus,
.btn-twitter:hover {
  background-color: #08bbff !important;
  outline: none !important;
}
.btn-twitter:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-twitter:hover,
.btn-twitter:focus {
  color: #fff !important;
}

.btn-google-plus,
.btn-google {
  background-color: #d34836;
  border-color: #b03626;
  color: #fff;
}
.btn-google-plus:focus,
.btn-google-plus:hover,
.btn-google:focus,
.btn-google:hover {
  background-color: #d85b4b !important;
  outline: none !important;
}
.btn-google-plus:active,
.btn-google:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-google-plus:hover,
.btn-google-plus:focus,
.btn-google:hover,
.btn-google:focus {
  color: #fff !important;
}

.btn-tumblr {
  background-color: #33516d;
  border-color: #23374a;
  color: #fff;
}
.btn-tumblr:focus,
.btn-tumblr:hover {
  background-color: #3b5e7e !important;
  outline: none !important;
}
.btn-tumblr:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-tumblr:hover,
.btn-tumblr:focus {
  color: #fff !important;
}

.btn-pinterest {
  background-color: #ce2127;
  border-color: #a21a1f;
  color: #fff;
}
.btn-pinterest:focus,
.btn-pinterest:hover {
  background-color: #dd2b32 !important;
  outline: none !important;
}
.btn-pinterest:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-pinterest:hover,
.btn-pinterest:focus {
  color: #fff !important;
}

.btn-linked-in {
  background-color: #007bb6;
  border-color: #005983;
  color: #fff;
}
.btn-linked-in:focus,
.btn-linked-in:hover {
  background-color: #008cd0 !important;
  outline: none !important;
}
.btn-linked-in:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-linked-in:hover,
.btn-linked-in:focus {
  color: #fff !important;
}

.btn-reddit {
  background-color: #cee3f8;
  border-color: #a1caf2;
  color: #555;
}
.btn-reddit:focus,
.btn-reddit:hover {
  background-color: #e4f0fb !important;
  outline: none !important;
}
.btn-reddit:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-reddit:hover,
.btn-reddit:focus {
  color: #555 !important;
  background-color: #b8d6f5 !important;
}

.btn-stumble-upon {
  background-color: #eb4823;
  border-color: #c93412;
  color: #fff;
}
.btn-stumble-upon:focus,
.btn-stumble-upon:hover {
  background-color: #ed5b3a !important;
  outline: none !important;
}
.btn-stumble-upon:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-stumble-upon:hover,
.btn-stumble-upon:focus {
  color: #fff !important;
}

.btn-soundcloud {
  background-color: #f50;
  border-color: #cc4400;
  color: #fff;
}
.btn-soundcloud:focus,
.btn-soundcloud:hover {
  background-color: #ff661a !important;
  outline: none !important;
}
.btn-soundcloud:active {
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-soundcloud:hover,
.btn-soundcloud:focus {
  color: #fff !important;
}

.no-scroll {
  overflow: hidden;
}

.box-shadow {
  box-shadow: 3px 3px 32px rgba(12, 18, 39, 0.08);
}

.word-wrap {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
}

.f {
  display: flex;

  &-01 {
    flex: 0.1;
  }
  &-02 {
    flex: 0.2;
  }
  &-03 {
    flex: 0.3;
  }
  &-04 {
    flex: 0.4;
  }
  &-05 {
    flex: 0.5;
  }
  &-06 {
    flex: 0.6;
  }
  &-07 {
    flex: 0.7;
  }
  &-08 {
    flex: 0.8;
  }
  &-09 {
    flex: 0;
  }
  &-1 {
    flex: 1;
  }

  &-row {
    flex-direction: row;
  }

  &-col {
    flex-direction: column;
  }

  &-xs-col {
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &-xs-row {
    @media (max-width: 480px) {
      flex-direction: row;
    }
  }
}

.fb {
  &-10 {
    flex-basis: 10%;
  }
  &-20 {
    flex-basis: 20%;
  }
  &-30 {
    flex-basis: 30%;
  }
  &-40 {
    flex-basis: 40%;
  }
  &-50 {
    flex-basis: 50%;
  }
  &-60 {
    flex-basis: 60%;
  }
  &-70 {
    flex-basis: 70%;
  }
  &-80 {
    flex-basis: 80%;
  }
  &-90 {
    flex-basis: 90%;
  }
  &-100 {
    flex-basis: 100%;
  }
}

.jc {
  &-center {
    justify-content: center;
    justify-items: center;
  }
  &-sb {
    justify-content: space-between;
    justify-items: space-between;
  }
}
.ac {
  &-center {
    align-content: center;
    align-items: center;
  }
}

.notificationContainer {
  @media all and (min-width: $screen-lg) {
    width: 40%;
  }
}
