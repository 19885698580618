@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  box-shadow: $box-shadow;
  padding: 20px 30px;
  overflow: visible;
}

.title {
  margin: 0 0 25px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.error {
  color: $brand-danger;
}
