@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.text {
  display: block;
  vertical-align: middle;
  margin: 0;
  white-space: nowrap;

  @media all and (min-width: $screen-sm) {
    margin-right: 10px;
    display: inline-block;
  }
}

.toggle {
  display: block;
  vertical-align: middle;
  margin-top: 10px;

  @media all and (min-width: $screen-sm) {
    margin-top: 0;
    display: inline-block;
  }
}
