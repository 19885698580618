html {
  position: relative;
  min-height: 100%;
}

.app {
  .brand {
    width: 95px;
  }

  .brand > img {
    width: 95px;
    position: relative;
    top: -6px;
  }

  nav :global(.fa) {
    font-size: 2em;
    line-height: 20px;
  }
}
