@import '~react-telephone-input/css/default.css';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

:global {
  .react-tel-input {
    width: 100% !important;

    input[type='tel'] {
      height: 46px !important;
      font-size: 16px !important;
    }

    .selected-flag {
      height: 100% !important;
      z-index: 1 !important;
    }

    .country-list {
      top: 46px !important;
      z-index: 1 !important;
      max-width: 260px;

      @media screen and (min-width: $screen-md) {
        max-width: 273px;
      }

      @media screen and (min-width: $screen-lg) {
        max-width: 340px;
      }
    }
  }
}
