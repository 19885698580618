@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.projectCard {
  background-color: white;
  color: black;
  padding: 12px;
  box-shadow: $box-shadow;
  border-radius: 8px;
  display: flex;
  margin: 20px 0px;
  flex-grow: 1;

  &:hover {
    background: rgba(7, 119, 218, 0.15);
    text-decoration: none;
  }
}

.projectInfo {
  margin-bottom: 5px;
  margin-left: 15px;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
  cursor: pointer;
  color: black;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
    color: black;
  }

  &:active {
    text-decoration: none;
  }
}

.projectTitle {
  font-size: 16px;
  font-weight: 700;
}

.projectButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  align-items: center;
  display: flex;
  gap: 5px;
  padding: 10px 12px;
  font-weight: 700;
}

.desktop {
  @media (max-width: $screen-sm) {
    display: none;
  }
}

.mobile {
  @media (min-width: $screen-sm) {
    display: none;
  }
}
