.portfolio-avatar {
  display: block;
  overflow: hidden;
  width: em-calc(150);
  height: em-calc(150);

  @media (max-width: $screen-xs) {
    align-self: center;
    justify-self: center;
  }

  @media (min-width: $screen-sm) {
    width: em-calc(155);
    height: em-calc(155);
  }
  @media (min-width: $screen-md) {
    width: em-calc(165);
    height: em-calc(165);
  }
  @media (min-width: $screen-lg) {
    width: em-calc(165);
    height: em-calc(165);
  }
}

// .portfolio-avatar--edit {
//   position: absolute;
//   top: 24%;
//   left: 21.5%;

//   @media (min-width: 570px) {
//     right: 48%;
//   }
//   @media (min-width: $screen-sm) {
//     top: 17%;
//     right: 48.5%;
//   }
//   @media (min-width: $screen-md) {
//     top: 21%;
//     right: 49%;
//   }
//   @media (min-width: $screen-lg) {
//     top: 24%;
//     left: 21.5%;
//   }
//   @media (min-width: 2000px) {
//     right: 49.5%;
//   }
// }

.desktopDisplayRightPadding {
  @media only screen and (min-width: $screen-md) {
    padding-right: 0px;
  }
}

.desktopDisplayRightMargin {
  @media only screen and (min-width: $screen-md) {
    margin: 25px auto;
  }
}

.desktopDisplayLeft {
  @media only screen and (min-width: $screen-md) {
    padding-left: 0px;
  }
}
