/**
 * Override Bootstrap styles that you can't modify via variables here.
 *
 */

@import './variables.scss';
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap';

@import '../../node_modules/react-s-alert/dist/s-alert-default';
@import '../../node_modules/react-s-alert/dist/s-alert-css-effects/jelly';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-sans-serif;
}

.s-alert-box {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  font-size: 1.5em;
  top: 30px;
  text-align: center;
  z-index: 1060;

  .s-alert-close {
    // display: none;
  }
}

.s-alert-top {
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
}

@media (min-width: 768px) {
  .s-alert-top {
    max-width: 50%;
  }
}

@import './app.scss';

button.close {
  color: #595959;
  font-size: 27px;
  line-height: 1.19;
  opacity: 1;
  text-shadow: none;
}

button:disabled {
  opacity: 0.3 !important;
}

a {
  text-decoration: none;
  color: $brand-primary;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: underline;
  filter: brightness(85%);
}

h1 {
  color: $brand-purple;
  margin: 30px 0px;
  text-transform: uppercase;
  @media screen and (max-width: $screen-xs) {
    font-size: 30px;
    margin: 20px 0px;
  }
}

h2 {
  color: #777;
}

h1,
h2 {
  font-weight: 700;
}

h3,
h4,
h5,
h6 {
}

.navbar {
  color: #fff;
  margin-bottom: 0px;

  .navbar-nav {
    > li > a {
      &:hover {
        color: $brand-primary;
        text-decoration-color: $brand-primary;
      }
    }

    > .open,
    > .open > a,
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: inherit;
        text-decoration: 2px underline;
        text-decoration-color: $navbar-default-link-active-bg;
        text-underline-offset: 5px;
      }

      &:hover {
        color: $brand-primary;
        text-decoration-color: $brand-primary;
      }
    }

    // for svg in closed NavDropdown
    > li > a > span > svg {
      transition: fill 0.3s;
      &:hover,
      &:focus {
        fill: $brand-primary;
        // border-bottom: 2px #fff solid;
      }
    }
    // for svg in open NavDropdown
    > .open > a > span > svg {
      &,
      &:hover,
      &:focus {
        fill: $brand-primary;
        border-bottom: 2px $brand-primary solid;
      }
    }
  }
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

.aside-text {
  color: #aaa;
}

.pointer {
  cursor: pointer;
}

p {
  font-weight: 400;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

#image-dropdown {
  padding: 16px 15px;
}

#icon-dropdown {
  padding: 20px 15px;
}

.btn-lg {
  border-radius: 4px;
}

.no-decoration {
  color: inherit;

  &:link {
    text-decoration: none;
    color: inherit;
  }

  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.bullet-spacer {
  &:before {
    content: '\2022';
    color: #999;
    padding: 0 em-calc(5);
  }
}
