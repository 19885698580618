@import 'src/theme/variables.scss';

.container {
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 20px;

  &.noPadding {
    padding: 0;
  }
}

.item {
  padding: 20px;

  &.noPadding {
    padding: 0;
  }
}

.separators > .item:not(:last-child) {
  border-bottom: 1px solid $body-bg;
}

.header {
  font-size: 20px;
  color: #333;
  position: relative;
  font-weight: 700;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
