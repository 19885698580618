@import 'src/theme/variables.scss';

.banner {
  text-align: center;
  transition: max-height 0.5s ease-in-out;
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.noOverflow {
  overflow: unset;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
}

.message {
  padding: 20px 40px;
}

.closed {
  max-height: 0px;
}

.open {
  max-height: 400px;
}

.primary {
  background-color: $brand-primary;
}

.secondary {
  background-color: $brand-secondary;
}

.success {
  background-color: $brand-success;
}

.warning {
  background-color: $brand-warning;
}

.danger {
  background-color: $brand-danger;
}

.info {
  background-color: $brand-info;
}

.purple {
  background-color: $brand-purple;
}

.white {
  background-color: #fff;
}
