@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

$incompleteStepColour: #ffffff;
$completedStepColour: $brand-success;

.onboardingBanner {
  color: white;

  z-index: 1;
  overflow: hidden;
  position: relative;
  max-height: 400px;

  background-color: $brand-dark;
  box-shadow: 3px 3px 32px rgba(12, 18, 39, 0.2);
  border-top: 1px solid #222;
  // background: linear-gradient(to left top, #322954, #363c6e) 0 0 no-repeat !important;

  .onboardingBannerContainer {
    padding: 10px 0;
  }
}

.rankBannerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;

  @media all and (max-width: $screen-sm) {
    flex-direction: column;

    .rankProgressBarContainer,
    .rankStepsContainer {
      width: 80%;
      max-width: 400px;
      min-width: 250px;
    }
  }

  .rankBannerTitle {
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    @media all and (max-width: $screen-sm) {
      margin-top: 5px;
    }
  }

  .rankProgressBarContainer {
    display: flex;
    flex-direction: column;
    flex: 0.6;

    .rankProgressBar {
      height: 30px;

      align-items: center;
      vertical-align: middle;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rankStepsContainer {
    .rankStepsTitle {
      margin-top: 10px;
      margin-bottom: 10px;

      font-size: 1.1em;
      font-weight: bold;

      text-align: center;
    }

    .rankStepContainer {
      margin: 5px 0;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .rankStepLink {
        color: $incompleteStepColour;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rankStepLabel {
        font-size: 1.1em;
        margin-right: 20px;

        flex: 1;
        display: flex;
        align-items: center;

        .completedLabel {
          text-decoration: line-through;
          color: $completedStepColour;
        }

        .rankStepTooltip {
          margin-left: 5px;
          color: $incompleteStepColour;
        }

        .completedTooltip {
          color: $completedStepColour;
        }
      }

      .rankStepCheckbox {
        height: 22px;
        width: 22px;
        min-width: 30px;
        min-height: 22px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
